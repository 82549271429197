/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCzuN9Py5KJpdZMOORR94vu1bXxX71iOHw",
  "appId": "1:312333611341:web:3b037d041d0df75ee34704",
  "authDomain": "schooldog-i-quitman-ga.firebaseapp.com",
  "measurementId": "G-S63SBZYXP1",
  "messagingSenderId": "312333611341",
  "project": "schooldog-i-quitman-ga",
  "projectId": "schooldog-i-quitman-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-quitman-ga.appspot.com"
}
